import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Header from '../components/header'
import Quote from '../components/quote'
import Columns from '../components/columns'

const image = require('../images/samples/retirement1.png')

const ContactPage = () => (
  <Layout>
    <Helmet
      title={'Contact Adage Financial Consultants '}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, located in Central Arkansas has been serving clients for over 30 years.  Contact us for your free, no-pressure consultation.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Contact Us'} image={image} />
    <Columns background>
      <div className="marketing__column">
        <Quote
          text={
            // tslint:disable-next-line:max-line-length
            'This free, no-pressure consultation can help give you a better understanding of "where you are" and "where you need to go" to secure your financial future.'
          }
        />
      </div>
      <div className="marketing__column">
        <p>
          With so many options available to those looking to grow and protect
          hard-earned their assets, cutting through the clutter and finding the
          right solutions can be a challenge. At Adage Financial Consultants, we
          understand these unique challenges and are driven to craft
          personalized, results-focused financial strategies for our clients.
        </p>
        <p>
          If you have questions about your financial future and would like to
          explore the financial planning options that are available, please use
          the simple form below to schedule your complimentary consultation.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default ContactPage
